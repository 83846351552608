export const environment = {
    production: true,
    ageGateExitUrl: 'https://www.youtube.com/watch?v=B2aBj80X6eM&ab_channel=CareBears',
    socialMedia: {
        facebook: {
            url: 'https://www.facebook.com/100088665310691'
        },
        twitter: {
            url: 'https://twitter.com/GrownUps233332'
        },
        patreon: {
            url: 'https://www.patreon.com/user?u=57243562'
        },
        discord: {
            url: 'https://discord.gg/CEAUMprPcq'
        }
    },
    azure: {
        storage: {
            host: 'https://67726f776e2d7570733a70.blob.core.windows.net',
            mediaContainer: 'media'
        }
    },
    payment: {
        transactionFees: [
            {
                currency: 'EUR',
                amount: 1.99,
                paymentMethods: ['card']
            }
        ]
    },
    centrobill: {
        host: 'https://api.centrobill.com'
    },
    allowedMessageOrigins: ['https://wp.grown-ups.net'],
    analytics: {
        redTrack: {
            trackingUrl: 'https://rt.grown-ups.net/postback'
        },
        preserveQueryParams: [
            'utm_id',
            'utm_source',
            'utm_medium',
            'utm_campaign',
            'utm_term',
            'utm_content',
            'rtkcid',
            'cmpid',
            'clickid'
        ]
    },
    xenoLinks: [
        {
            id: '08b89875-076e-4478-a1f2-dde510ced0c1',
            urls: {
                de: 'https://wp.grown-ups.net/wild-life-en/',
                en: 'https://wp.grown-ups.net/wild-life-en/'
            }
        },
        {
            id: 'bb50a28a-9241-4c43-9e91-613c3e1b844f',
            urls: {
                en: 'https://wp.grown-ups.net/treasure-of-nadia-en/',
                de: 'https://wp.grown-ups.net/treasure-of-nadia-en/'
            }
        },
        {
            id: '90b8f654-d6f7-4d90-9eb0-915ac8398731',
            urls: {
                en: 'https://wp.grown-ups.net/wildlife-en-2/',
                de: 'https://wp.grown-ups.net/wildlife-de-2/'
            }
        }
    ],
    playfab: {
        titleId: '854B4',
        bannerCatalog: 'Banners',
        productCatalog: 'Games',
        nullBannerId: '743d172d-8eb1-4ff5-93a4-b83fd0810bae'
    },
    api: {
        host: 'https://grown-ups-store-service-app-prod-westeurope.azurewebsites.net'
    }
};
